// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/prayertextkaraoke/PrayerTextKaraoke.css */

.container {
    margin-top: 20px;
  }
  
  .prayer-text-karaoke {
    margin: 0 auto;
    padding: 10px;
    max-width: 100%;
    height: 200px; /* Adjust height as needed */
    background-color: #f8f9fa; /* Light background for visibility */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Align text to the left for readability */
    font-size: 18px; /* Adjust font size for readability */
    line-height: 1.6; /* Line height for better spacing between lines */
  }
  
  .scroll-area-sm {
    position: relative;
    height: 100%;
    max-height: 200px; /* Ensures the scrollbar is visible */
    overflow: hidden;
  }
  
  .highlighted {
    color: black;
    font-weight: bold;
  }
  
  .unhighlighted {
    color: grey;
  }
  
  /* Style for the currently spoken word */
  .current-word {
    color: red;
    text-decoration: underline;
  }
  
  .text-center {
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/prayertextkaraoke/PrayerTextKaraoke.css"],"names":[],"mappings":"AAAA,2DAA2D;;AAE3D;IACI,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,aAAa;IACb,eAAe;IACf,aAAa,EAAE,4BAA4B;IAC3C,yBAAyB,EAAE,oCAAoC;IAC/D,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB,EAAE,2CAA2C;IAC7D,eAAe,EAAE,qCAAqC;IACtD,gBAAgB,EAAE,iDAAiD;EACrE;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,iBAAiB,EAAE,qCAAqC;IACxD,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,WAAW;EACb;;EAEA,wCAAwC;EACxC;IACE,UAAU;IACV,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* src/components/prayertextkaraoke/PrayerTextKaraoke.css */\n\n.container {\n    margin-top: 20px;\n  }\n  \n  .prayer-text-karaoke {\n    margin: 0 auto;\n    padding: 10px;\n    max-width: 100%;\n    height: 200px; /* Adjust height as needed */\n    background-color: #f8f9fa; /* Light background for visibility */\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    text-align: left; /* Align text to the left for readability */\n    font-size: 18px; /* Adjust font size for readability */\n    line-height: 1.6; /* Line height for better spacing between lines */\n  }\n  \n  .scroll-area-sm {\n    position: relative;\n    height: 100%;\n    max-height: 200px; /* Ensures the scrollbar is visible */\n    overflow: hidden;\n  }\n  \n  .highlighted {\n    color: black;\n    font-weight: bold;\n  }\n  \n  .unhighlighted {\n    color: grey;\n  }\n  \n  /* Style for the currently spoken word */\n  .current-word {\n    color: red;\n    text-decoration: underline;\n  }\n  \n  .text-center {\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
