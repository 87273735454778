// src/pages/Signup.js
import React from 'react';
import './Auth.css';

function Signup() {
  const handleGoogleSignup = () => {
    window.location.href = `${process.env.REACT_APP_API}/auth/google`;
  };

  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      <button onClick={handleGoogleSignup} className="btn btn-google">
        Sign up with Google Account
      </button>
    </div>
  );
}

export default Signup;
