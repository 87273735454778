// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virtual-rosary {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  
  .rosary-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .bead {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.5s, box-shadow 0.5s;
  }
  
  .bead.our-father {
    background-color: #f5a623;
  }
  
  .bead.hail-mary {
    background-color: #36a2eb;
  }
  
  .bead.glow {
    background-color: yellow;
    box-shadow: 0 0 10px yellow;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/rosary/virtualrosary/VirtualRosary.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,kDAAkD;EACpD;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,wBAAwB;IACxB,2BAA2B;EAC7B","sourcesContent":[".virtual-rosary {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 20px;\n  }\n  \n  .rosary-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .bead {\n    width: 20px;\n    height: 20px;\n    background-color: #ccc;\n    border-radius: 50%;\n    transition: background-color 0.5s, box-shadow 0.5s;\n  }\n  \n  .bead.our-father {\n    background-color: #f5a623;\n  }\n  \n  .bead.hail-mary {\n    background-color: #36a2eb;\n  }\n  \n  .bead.glow {\n    background-color: yellow;\n    box-shadow: 0 0 10px yellow;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
