// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/signup/styles.css */

/* For mobile devices */
@media (max-width: 768px) {
    .app-login-box h4 {
      font-size: 1.2rem;
    }
  
    .app-login-box span.text-success {
      font-size: 1.1rem;
    }
  
    .btn {
      font-size: 0.9rem;
    }
  
    .accordion-wrapper h5 {
      font-size: 1rem;
    }
  
    .slider-content h3 {
      font-size: 1.5rem;
    }
  
    .slider-content p {
      font-size: 1rem;
    }
  
    .form-control {
      font-size: 0.9rem;
    }
  }
  
  /* For very small mobile devices (like iPhone SE) */
  @media (max-width: 576px) {
    .app-login-box h4 {
      font-size: 1rem;
    }
  
    .app-login-box span.text-success {
      font-size: 0.9rem;
    }
  
    .btn {
      font-size: 0.8rem;
    }
  
    .accordion-wrapper h5 {
      font-size: 0.9rem;
    }
  
    .slider-content h3 {
      font-size: 1.3rem;
    }
  
    .slider-content p {
      font-size: 0.9rem;
    }
  
    .form-control {
      font-size: 0.85rem;
    }
  }
  
/*  */`, "",{"version":3,"sources":["webpack://./src/signup/styles.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B,uBAAuB;AACvB;IACI;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;EACF;;EAEA,mDAAmD;EACnD;IACE;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,kBAAkB;IACpB;EACF;;AAEF,KAAK","sourcesContent":["/* src/signup/styles.css */\n\n/* For mobile devices */\n@media (max-width: 768px) {\n    .app-login-box h4 {\n      font-size: 1.2rem;\n    }\n  \n    .app-login-box span.text-success {\n      font-size: 1.1rem;\n    }\n  \n    .btn {\n      font-size: 0.9rem;\n    }\n  \n    .accordion-wrapper h5 {\n      font-size: 1rem;\n    }\n  \n    .slider-content h3 {\n      font-size: 1.5rem;\n    }\n  \n    .slider-content p {\n      font-size: 1rem;\n    }\n  \n    .form-control {\n      font-size: 0.9rem;\n    }\n  }\n  \n  /* For very small mobile devices (like iPhone SE) */\n  @media (max-width: 576px) {\n    .app-login-box h4 {\n      font-size: 1rem;\n    }\n  \n    .app-login-box span.text-success {\n      font-size: 0.9rem;\n    }\n  \n    .btn {\n      font-size: 0.8rem;\n    }\n  \n    .accordion-wrapper h5 {\n      font-size: 0.9rem;\n    }\n  \n    .slider-content h3 {\n      font-size: 1.3rem;\n    }\n  \n    .slider-content p {\n      font-size: 0.9rem;\n    }\n  \n    .form-control {\n      font-size: 0.85rem;\n    }\n  }\n  \n/*  */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
