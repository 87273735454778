// src/pages/PrivacyPolicy.js

import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="content-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: 2/14/2025</p>

      <h2>1. Introduction</h2>
      <p>Welcome to littleone.life. We respect your privacy and are committed to protecting your personal data.</p>

      <h2>2. Information We Collect</h2>
      <p>We collect and store the following information when you use our services:</p>
      <ul>
        <li>Your email address when you sign in with Google.</li>
        <li>Google Sheets data that you choose to log through our platform.</li>
        <li>Basic analytics to improve our services (non-identifiable data).</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Authenticate your identity.</li>
        <li>Store your devotional tracking data.</li>
        <li>Improve our features based on user activity.</li>
      </ul>

      <h2>4. Data Sharing & Security</h2>
      <p>We **do not** sell or share your data with third parties. Your devotional data is stored securely in your Google Sheets, and you can delete it at any time.</p>

      <h2>5. Google OAuth & API Usage</h2>
      <p>We use Google OAuth for authentication and access your Google Sheets only to store your devotional records.</p>

      <h2>6. Contact</h2>
      <p>If you have any concerns, contact us at <strong>thebillvideos@gmail.com</strong>.</p>
    </div>
  );
}

export default PrivacyPolicy;
