// src/pages/Signin.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserSheets } from '../api/googleSheets';

function Signin() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function checkSession() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/auth/check-session`, {
          method: "GET",
          credentials: "include",
        });

        const sessionData = await response.json();
        if (sessionData.isAuthenticated) {
          console.log("✅ User is authenticated.");
          setIsAuthenticated(true);
        } else {
          console.log("❌ User is not authenticated.");
        }
      } catch (error) {
        console.error("❌ Error checking session:", error);
      }
    }

    checkSession();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      async function createSheets() {
        try {
          await createUserSheets();
          console.log("✅ User sheets created.");
        } catch (error) {
          console.error("❌ Failed to create user sheets:", error);
        }
      }
      createSheets();
    }
  }, [isAuthenticated]); // ✅ Runs only after authentication

  const handleGoogleSignin = () => {
    window.location.href = `${process.env.REACT_APP_API}/auth/google`;
  };

  return (
    <div className="auth-container">
      <h2>Sign In</h2>
      <button onClick={handleGoogleSignin} className="btn btn-google">
        Sign in with Google
      </button>
    </div>
  );
}

export default Signin;
