// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BottomBar.css */

.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa; /* Adjust background color as needed */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    height: 60px; /* Set a fixed height for the BottomBar */
}

.bottom-bar a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000000; /* Change non-active link color to black */
    font-size: 14px; /* Ensure the font size is appropriate */
}

.bottom-bar a.active {
    color: #a72c28; /* Adjust active link color to a vibrant green */
}

.bottom-bar a span {
    font-size: 12px;
    margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/bottombar/BottomBar.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,WAAW;IACX,yBAAyB,EAAE,sCAAsC;IACjE,yCAAyC;IACzC,aAAa;IACb,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,YAAY,EAAE,yCAAyC;AAC3D;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;IACrB,cAAc,EAAE,0CAA0C;IAC1D,eAAe,EAAE,wCAAwC;AAC7D;;AAEA;IACI,cAAc,EAAE,gDAAgD;AACpE;;AAEA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":["/* BottomBar.css */\n\n.bottom-bar {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    background-color: #f8f9fa; /* Adjust background color as needed */\n    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);\n    z-index: 1050;\n    display: flex;\n    justify-content: space-around;\n    padding: 10px 0;\n    height: 60px; /* Set a fixed height for the BottomBar */\n}\n\n.bottom-bar a {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-decoration: none;\n    color: #000000; /* Change non-active link color to black */\n    font-size: 14px; /* Ensure the font size is appropriate */\n}\n\n.bottom-bar a.active {\n    color: #a72c28; /* Adjust active link color to a vibrant green */\n}\n\n.bottom-bar a span {\n    font-size: 12px;\n    margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
