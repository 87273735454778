import React, { useState } from 'react';
import styled from 'styled-components';

// PAGE CONTAINER & HEADER STYLES
const PageContainer = styled.div`
  padding: 24px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  background: #f9f9f9;
  min-height: 100vh;
`;

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-family: 'Georgia', serif;
  font-size: 2rem;
  color: #333;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #666;
`;

// PROGRESS BAR STYLES
const ProgressBarContainer = styled.div`
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  margin: 16px 0;
`;

const ProgressBarFiller = styled.div`
  height: 8px;
  width: ${props => props.progress}%;
  background: #a1887f; /* soft accent color */
  transition: width 0.5s ease-in-out;
`;

const ProgressBar = ({ progress }) => (
  <ProgressBarContainer>
    <ProgressBarFiller progress={progress} />
  </ProgressBarContainer>
);

// FILTER SECTION STYLES
const FilterSection = styled.section`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const FilterLabel = styled.label`
  margin-right: 8px;
  font-size: 1rem;
  color: #333;
`;

const FilterSelect = styled.select`
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// GRID & CARD STYLES
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const CardTitle = styled.h3`
  font-family: 'Georgia', serif;
  font-size: 1.25rem;
  margin: 8px 0;
  color: #333;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const DetailSection = styled.div`
  margin-top: 12px;
  font-size: 0.9rem;
  color: #333;
  display: ${props => (props.expanded ? 'block' : 'none')};
`;

// ACHIEVEMENT CARD COMPONENT
const AchievementCard = ({ achievement }) => {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <Card onClick={() => setExpanded(!expanded)}>
      <Icon src={achievement.icon} alt={achievement.title} />
      <CardTitle>{achievement.title}</CardTitle>
      <CardDescription>{achievement.shortDescription}</CardDescription>
      <DetailSection expanded={expanded}>
        <p><strong>Criteria:</strong> {achievement.criteria}</p>
        <p><strong>Date Earned:</strong> {achievement.dateEarned}</p>
        {achievement.levelInfo && (
          <div>
            <h4>Level Details:</h4>
            <p>{achievement.levelInfo}</p>
          </div>
        )}
      </DetailSection>
    </Card>
  );
};

// ACHIEVEMENTS PAGE COMPONENT
const AchievementsPage = () => {
  // Example progress value (adjust to your actual logic)
  const [progress, setProgress] = useState(65); // 65% toward next achievement

  // Example filter state
  const [filter, setFilter] = useState('date'); // Options: 'date' or 'category'
  
  // Dummy achievements data (adjust with real data and image paths)
  const achievementsData = [
    {
      id: 1,
      icon: '/icons/rosary-master.png',
      title: 'Rosary Master',
      shortDescription: 'Completed 50 days of the rosary.',
      criteria: 'Pray the rosary daily for 50 consecutive days.',
      dateEarned: '2025-01-15',
      levelInfo: 'Heavenly Level: Fourth, Faith Level: Faithful, Maturity Level: Young Adult'
    },
    {
      id: 2,
      icon: '/icons/faithful-devotee.png',
      title: 'Faithful Devotee',
      shortDescription: 'Logged devotions for 100 consecutive days.',
      criteria: 'Record devotions daily for 100 days.',
      dateEarned: '2025-02-01',
      levelInfo: 'Heavenly Level: Fifth, Faith Level: Good, Maturity Level: Adult'
    },
    // Add more achievement objects as needed...
  ];

  // Filtering logic (expand as needed)
  const filteredAchievements = achievementsData; // For now, no change based on filter

  return (
    <PageContainer>
      <Header>
        <Title>Achievements</Title>
        <Subtitle>Celebrate your journey of devotion and growth.</Subtitle>
        <ProgressBar progress={progress} />
      </Header>
      
      <FilterSection>
        <FilterLabel htmlFor="filter-select">Sort by:</FilterLabel>
        <FilterSelect
          id="filter-select"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        >
          <option value="date">Date Earned</option>
          <option value="category">Category</option>
        </FilterSelect>
      </FilterSection>
      
      <GridContainer>
        {filteredAchievements.map((achievement) => (
          <AchievementCard key={achievement.id} achievement={achievement} />
        ))}
      </GridContainer>
      
      {/* Social sharing components can be integrated below as needed */}
    </PageContainer>
  );
};

export default AchievementsPage;
