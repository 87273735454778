// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Dashboard from './pages/Dashboard';
import Marketplace from './pages/Marketplace';
import Profile from './pages/Profile';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import AchievementsPage from './pages/AchievementsPage';
import Rosaries from './pages/devotions/Rosaries';  // Import the Rosaries page
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected user routes */}
        <Route 
          path="/dashboard" 
          element={<PrivateRoute component={Dashboard} />} 
        />
        <Route 
          path="/profile" 
          element={<PrivateRoute component={Profile} />} 
        />
        <Route 
          path="/dashboard/achievements" 
          element={<PrivateRoute component={AchievementsPage} />} 
        />
        {/* Rosaries page: This route is for tracking rosary devotions and will integrate with Google Forms/Spreadsheets */}
        <Route 
          path="/prayers/rosary" 
          element={<PrivateRoute component={Rosaries} />} 
        />

        {/* Example protected admin route (if you have one) */}
        {/* <Route 
          path="/admin/dashboard" 
          element={<AdminRoute component={AdminDashboard} />} 
        /> */}
      </Routes>
    </Router>
  );
}

export default App;
