// src/pages/Profile.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Profile.css';

// In a real app, you might use a Lottie animation or similar for your avatar.
// For now, we use placeholder images based on the user's spiritual level.
function Profile() {
  // Placeholder user data; in a complete app these values come from your backend.
  const user = {
    name: "John Doe",
    email: "johndoe@example.com",
    spiritualLevel: 0, // Change this value to test different avatar states (-1, 0, 1, …, 7)
    heavenlyLevel: "Zero",
    faithLevel: "None (Faith of Others)",
    maturityLevel: "Fetus",
    lifeCategory: "Non-Christian Faith (Good Pagan) – Ignored God, Lived a Pleasurable Life",
    seedCategory: "Bad Ground",
    kingdomCategory: "Purgatory",
    rewards: 0,
    churchLevel: "Hedonism",
    templeEntry: "Outer Gate"
  };

  // Choose an avatar image based on the user's spiritual level.
  // For demonstration, we assume you have these images in your assets/images folder.
  let avatarImage;
  if (user.spiritualLevel <= 0) {
    avatarImage = require('../assets/images/fetus_avatar.png');
  } else if (user.spiritualLevel >= 7) {
    avatarImage = require('../assets/images/wise_avatar.png');
  } else {
    avatarImage = require('../assets/images/child_avatar.png'); // For in-between levels
  }

  // Define timeline milestones as derived from your guidance chart.
  const milestones = [
    { level: -1, title: "Minus One", description: "Disbelief, Embryo" },
    { level: 0, title: "Zero", description: "Fetus – Non-Christian Faith" },
    { level: 1, title: "First", description: "Baby – Initial Zeal but Never Grew" },
    { level: 2, title: "Second", description: "Adolescent – Limited Devotion" },
    { level: 3, title: "Third", description: "Teenager – Simulated Christian" },
    { level: 4, title: "Fourth", description: "Young Adult – Faithful" },
    { level: 5, title: "Fifth", description: "Adult – Devoutly Seeking" },
    { level: 6, title: "Sixth", description: "Middle-Aged – Strong" },
    { level: 7, title: "Seventh", description: "Full Maturity – Perfect" }
  ];

  return (
    <div className="profile-container">
      {/* Profile Header with Avatar and Basic Info */}
      <header className="profile-header">
        <div className="profile-avatar">
          <img src={avatarImage} alt="Animated Avatar" />
        </div>
        <div className="profile-info">
          <h1>{user.name}</h1>
          <p>{user.email}</p>
          <Link to="/profile/edit" className="btn edit-profile">Edit Profile</Link>
        </div>
      </header>

      {/* Spiritual Progress & Statistics */}
      <section className="profile-stats">
        <h2>Spiritual Progress</h2>
        <div className="stats-grid">
          <div className="stat-card">
            <h3>Heavenly Level</h3>
            <p>{user.heavenlyLevel}</p>
          </div>
          <div className="stat-card">
            <h3>Faith Level</h3>
            <p>{user.faithLevel}</p>
          </div>
          <div className="stat-card">
            <h3>Maturity Level</h3>
            <p>{user.maturityLevel}</p>
          </div>
          <div className="stat-card">
            <h3>Rewards (Crowns)</h3>
            <p>{user.rewards}</p>
          </div>
          <div className="stat-card">
            <h3>Church Level</h3>
            <p>{user.churchLevel}</p>
          </div>
          <div className="stat-card">
            <h3>Temple Entry</h3>
            <p>{user.templeEntry}</p>
          </div>
        </div>
      </section>

      {/* Categories */}
      <section className="profile-categories">
        <h2>Categories</h2>
        <div className="categories-grid">
          <div className="category-card">
            <h3>Life Category</h3>
            <p>{user.lifeCategory}</p>
          </div>
          <div className="category-card">
            <h3>Seed Category</h3>
            <p>{user.seedCategory}</p>
          </div>
          <div className="category-card">
            <h3>Kingdom Category</h3>
            <p>{user.kingdomCategory}</p>
          </div>
        </div>
      </section>

      {/* Spiritual Journey Timeline */}
      <section className="profile-timeline">
        <h2>Your Spiritual Journey</h2>
        <ul className="timeline">
          {milestones.map((milestone) => (
            <li key={milestone.level} className={user.spiritualLevel === milestone.level ? "active" : ""}>
              <span className="milestone-level">{milestone.title}</span>
              <span className="milestone-desc">{milestone.description}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Achievements and Rewards */}
      <section className="profile-achievements">
        <h2>Achievements & Rewards</h2>
        <div className="achievements-grid">
          <div className="achievement-card">
            <h3>Badge: Rosary Master</h3>
            <p>Earned for logging 50 rosaries</p>
          </div>
          <div className="achievement-card">
            <h3>Badge: Faithful Devotee</h3>
            <p>Earned for consistent weekly prayers</p>
          </div>
          {/* Additional achievement cards can be added here */}
        </div>
      </section>

      {/* Recent Activity Feed */}
      <section className="profile-activity-feed">
        <h2>Recent Activity</h2>
        <ul className="activity-feed">
          <li>Logged 5 rosaries</li>
          <li>Attended Mass on April 1, 2025</li>
          <li>Saved a reflection journal entry</li>
          {/* More activity entries can be added here */}
        </ul>
      </section>
    </div>
  );
}

export default Profile;
