// src/api/googleSheets.js

const API = process.env.REACT_APP_API || 'https://www.littleone.life/api';


/**
 * Create user devotion sheets (called after signup)
 */
export const createUserSheets = async () => {
    try {
        const response = await fetch(`${API}/setup/create-user-sheets`, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" }
        });

        if (!response.ok) throw new Error("Failed to create user sheets");

        return await response.json();
    } catch (error) {
        console.error("Error creating user sheets:", error);
        throw error;
    }
};

/**
 * Add a new prayer entry to Google Sheets
 */
export const addDevotionEntry = async (devotionType, entryData) => {
    try {
        const response = await fetch(`${API}/setup/add-devotion-entry`, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ devotionType, entryData }),
        });

        if (!response.ok) throw new Error(`Failed to add ${devotionType} entry`);

        return await response.json();
    } catch (error) {
        console.error(`Error adding ${devotionType} entry:`, error);
        throw error;
    }
};

/**
 * Fetch user prayer data from Google Sheets
 */
export const getUserDevotionData = async (devotionType) => {
    try {
        const response = await fetch(`${API}/setup/get-devotion-data?devotionType=${devotionType}`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" }
        });

        if (!response.ok) throw new Error(`Failed to fetch ${devotionType} data`);

        return await response.json();
    } catch (error) {
        console.error(`Error fetching ${devotionType} data:`, error);
        throw error;
    }
};
