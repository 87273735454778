// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Auth.css */
.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .auth-container h2 {
    margin-bottom: 20px;
    font-family: 'Merriweather', serif;
  }
  
  .btn-google {
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    background-color: #DB4437; /* Google red */
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-google:hover {
    background-color: #C33D2E;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Auth.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;IACE,mBAAmB;IACnB,kCAAkC;EACpC;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,eAAe;IAC1C,WAAW;IACX,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/pages/Auth.css */\n.auth-container {\n    max-width: 400px;\n    margin: 50px auto;\n    padding: 30px;\n    text-align: center;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n  }\n  \n  .auth-container h2 {\n    margin-bottom: 20px;\n    font-family: 'Merriweather', serif;\n  }\n  \n  .btn-google {\n    padding: 12px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: #DB4437; /* Google red */\n    color: #fff;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .btn-google:hover {\n    background-color: #C33D2E;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
