// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prayer-line {
    color: blue;
    margin-bottom: 10px; /* Adjust this value for desired line spacing */
}

.generic-prayer-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/otherprayers/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB,EAAE,+CAA+C;AACxE;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".prayer-line {\n    color: blue;\n    margin-bottom: 10px; /* Adjust this value for desired line spacing */\n}\n\n.generic-prayer-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
