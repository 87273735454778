import React from 'react';

const Social = () => {
    return (
        <div>
            <h1>Social</h1>
            {/* Social functionality */}
        </div>
    );
};

export default Social;
