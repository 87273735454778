// src/pages/Signin.js
import React from 'react';
import './Auth.css'; // Create a shared CSS file for auth pages if desired

function Signin() {
  const handleGoogleSignin = () => {
    // Redirect the browser to your backend's Google OAuth route
    window.location.href = `${process.env.REACT_APP_API}/auth/google`;
  };

  return (
    <div className="auth-container">
      <h2>Sign In</h2>
      <button onClick={handleGoogleSignin} className="btn btn-google">
        Sign in with Google
      </button>
    </div>
  );
}

export default Signin;
