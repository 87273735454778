// src/api/googleForms.js

const API = process.env.REACT_APP_API || 'https://www.littleone.life/api';

export const copyRosaryForm = async () => {
    try {
        const response = await fetch(`${API}/setup/copy-rosary-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include' // Ensures session authentication
        });

        if (!response.ok) {
            throw new Error('Failed to copy Rosary Form');
        }

        const data = await response.json();
        console.log("New Rosary Form ID:", data.newFormId);

        return data;
    } catch (error) {
        console.error('Error copying Rosary Form:', error);
        throw error;
    }
};

export const getUserRosaryForm = async () => {
    try {
        const response = await fetch(`${API}/setup/get-rosary-form`, {
            method: 'GET',
            credentials: 'include' // ✅ Ensures session data is passed
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Rosary Form');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching Rosary Form:', error);
        throw error;
    }
};



/**
 * Copy a Google Form into the user's Google Drive.
 * @param {string} prayerType - Type of prayer (rosary, mass, confession, chaplet).
 * @param {string} userAccessToken - OAuth access token for Google API.
 * @returns {Promise<object>} - { success: true, newFormId }
 */
export const copyGoogleForm = async (prayerType) => {
    try {
        const response = await fetch(`${API}/setup/copy-google-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include', // Ensures session cookies are sent
            body: JSON.stringify({ prayerType })
        });

        if (!response.ok) {
            throw new Error('Failed to copy Google Form');
        }

        const data = await response.json();
        console.log("New Google Form ID:", data.newFormId); // Debugging

        return data;
    } catch (error) {
        console.error('Error copying Google Form:', error);
        throw error;
    }
};


/**
 * Create and link a response Google Spreadsheet for the user's Form.
 * @param {string} userAccessToken - OAuth access token for Google API.
 * @returns {Promise<object>} - { success: true, sheetId }
 */
export const createResponseSheet = async () => {
    try {
        const response = await fetch(`${API}/setup/link-response-sheet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include' // Ensures session authentication is used
        });

        if (!response.ok) {
            throw new Error('Failed to create response sheet');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating response sheet:', error);
        throw error;
    }
};


/**
 * Fetch the user's prayer log data from Google Sheets.
 * @param {string} userAccessToken - OAuth access token.
 * @param {string} sheetId - Google Sheet ID storing the user's responses.
 * @returns {Promise<object>} - Data from the sheet
 */
export const getPrayerData = async (sheetId) => {
    try {
        const response = await fetch(`${API}/setup/get-prayer-data?sheetId=${sheetId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include' // Ensures session authentication is used
        });

        if (!response.ok) {
            throw new Error('Failed to fetch prayer data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching prayer data:', error);
        throw error;
    }
};
