// src/pages/Signup.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserSheets } from '../api/googleSheets';
import './Auth.css';

function Signup() {
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAndCreateSheets() {
      try {
        await createUserSheets();
        console.log("✅ User sheets ensured.");
      } catch (error) {
        console.error("❌ Failed to create user sheets:", error);
      }
    }

    checkAndCreateSheets();
  }, []);

  const handleGoogleSignup = () => {
    window.location.href = `${process.env.REACT_APP_API}/auth/google`;
  };

  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      <button onClick={handleGoogleSignup} className="btn btn-google">
        Sign up with Google Account
      </button>
    </div>
  );
}

export default Signup;
