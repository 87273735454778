// src/pages/devotions/Rosaries.js
import React, { useState, useEffect } from 'react';
import { FaPrayingHands } from 'react-icons/fa';
import './Rosaries.css';
import { gapi } from 'gapi-script';
import { useUser } from '../../context/UserContext';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  "https://sheets.googleapis.com/$discovery/rest?version=v4"
];
const SCOPES = "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets";

function Rosaries() {
  const { user } = useUser();
  const [rosaryCount, setRosaryCount] = useState(0);
  const [level, setLevel] = useState(1);
  // We'll use state to store if gapi is fully initialized and the sign-in state.
  const [gapiSignedIn, setGapiSignedIn] = useState(false);
  const [formUrl, setFormUrl] = useState(null);
  
  // Separate effect: Initialize gapi once.
  useEffect(() => {
    console.log("CLIENT_ID:", CLIENT_ID);
    console.log("API_KEY:", API_KEY);
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        if (!authInstance) {
          console.error("gapi.auth2.getAuthInstance() returned null.");
        } else {
          console.log("gapi initialized. isSignedIn:", authInstance.isSignedIn.get());
          setGapiSignedIn(authInstance.isSignedIn.get());
          authInstance.isSignedIn.listen(setGapiSignedIn);
        }
      }).catch((error) => {
        console.error("Error initializing gapi client:", error);
      });
    });
  }, []);
    // runs only once

  // Separate effect: Once user data is available, fetch rosary count if spreadsheet is set.
  useEffect(() => {
    if (user && user.rosarySpreadsheetId && gapiSignedIn) {
      fetchRosaryCount(user.rosarySpreadsheetId);
    }
  }, [user, gapiSignedIn]); // runs whenever user or sign-in state changes

  const fetchRosaryCount = async (spreadsheetId) => {
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId,
        range: 'Sheet1!A1',
      });
      const count = parseInt(response.result.values[0][0], 10) || 0;
      setRosaryCount(count);
      calculateLevel(count);
    } catch (error) {
      console.error('Error fetching rosary count:', error);
    }
  };

  const calculateLevel = (count) => {
    setLevel(Math.floor(count / 10) + 1);
  };

  const addRosary = async () => {
    if (!user || !user.rosarySpreadsheetId) return;
    const newCount = rosaryCount + 1;
    setRosaryCount(newCount);
    calculateLevel(newCount);
    try {
      await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: user.rosarySpreadsheetId,
        range: 'Sheet1!A1',
        valueInputOption: 'RAW',
        resource: { values: [[newCount]] },
      });
    } catch (error) {
      console.error('Error updating rosary count:', error);
    }
  };

  const handleGoogleSignIn = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    if (authInstance) {
      authInstance.signIn().then(() => {
        setGapiSignedIn(true);
      }).catch((error) => {
        console.error("Google sign in error:", error);
      });
    }
  };

  const setupRosaryForm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/setup/rosary-form`, {
        method: 'POST',
        credentials: 'include',
      });
      const result = await response.json();
      if (result.formUrl) {
        setFormUrl(result.formUrl);
      } else {
        console.error("No form URL returned", result);
      }
    } catch (error) {
      console.error("Error setting up rosary form:", error);
    }
  };

  return (
    <div className="rosaries">
      <h1>Rosary Tracker</h1>
      <p>Track your daily rosary prayers and level up!</p>
      <div className="rosary-count">
        <FaPrayingHands className="rosary-icon" />
        <h2>{rosaryCount} Rosaries Prayed</h2>
      </div>
      <div className="level-info">
        <p>Current Level: {level}</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${(rosaryCount % 10) * 10}%` }}></div>
        </div>
      </div>
      {/* If gapi is signed in but no form has been set up yet */}
      {gapiSignedIn && (!user || !user.rosaryFormId) && !formUrl && (
        <button onClick={setupRosaryForm} className="setup-form-btn">
          Set Up Rosary Tracker
        </button>
      )}
      {/* Once the form URL is available (either from user or state), embed the form */}
      {(user && user.rosaryFormId) || formUrl ? (
        <iframe 
          src={user.rosaryFormId || formUrl} 
          title="Rosary Form" 
          width="100%" 
          height="600px" 
          frameBorder="0"
        ></iframe>
      ) : (
        // If not signed in with gapi, show a sign in button.
        !gapiSignedIn && (
          <button onClick={handleGoogleSignIn} className="google-signin-btn">
            Sign in with Google
          </button>
        )
      )}
      {/* Additionally, if a spreadsheet is set up, show a button to add a rosary */}
      {gapiSignedIn && user && user.rosarySpreadsheetId && (
        <button onClick={addRosary} className="add-rosary-btn">
          + Add Rosary
        </button>
      )}
    </div>
  );
}

export default Rosaries;
