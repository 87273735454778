// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Prayers.css */

.prayers-container {
    text-align: center;
    padding: 20px;
}

.prayers-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prayer-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.prayer-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none; /* Ensure no text decoration */
}

.prayer-icon img {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
}

.prayer-icon p {
    margin: 0;
    font-size: 14px;
    color: black; /* Ensure text color is black */
}

/* Add this rule to remove any link styling */
.prayer-icon a {
    text-decoration: none;
    color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/prayers/Prayers.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,qBAAqB,EAAE,8BAA8B;AACzD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,YAAY,EAAE,+BAA+B;AACjD;;AAEA,6CAA6C;AAC7C;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":["/* Prayers.css */\n\n.prayers-container {\n    text-align: center;\n    padding: 20px;\n}\n\n.prayers-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.prayer-icons {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.prayer-icon {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 10px;\n    cursor: pointer;\n    text-align: center;\n    text-decoration: none; /* Ensure no text decoration */\n}\n\n.prayer-icon img {\n    width: 64px;\n    height: 64px;\n    margin-bottom: 10px;\n}\n\n.prayer-icon p {\n    margin: 0;\n    font-size: 14px;\n    color: black; /* Ensure text color is black */\n}\n\n/* Add this rule to remove any link styling */\n.prayer-icon a {\n    text-decoration: none;\n    color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
