import React from 'react';

const Works = () => {
    return (
        <div>
            <h1>Works</h1>
            {/* Works functionality */}
        </div>
    );
};

export default Works;
