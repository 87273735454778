import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import homeBackground from '../assets/images/home_background.jpg';

function Home() {
  return (
    <>
      <header className="site-header">
        <nav className="navbar">
          <div className="navbar-logo">
            <Link to="/">littleone.life</Link>
          </div>
          <div className="navbar-links">
            <Link to="/signin" className="nav-link">Sign In</Link>
            <Link to="/signup" className="nav-link btn-signup">Sign Up</Link>
          </div>
        </nav>
        <div className="hero" style={{ backgroundImage: `url(${homeBackground})` }}>
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1>Welcome to littleone.life</h1>
            <p>Your spiritual journey, tracked and celebrated. Begin your lifelong Catholic journey of faith today.</p>
            <div className="hero-buttons">
              <Link to="/dashboard" className="btn btn-primary">Get Started</Link>
              <Link to="/marketplace" className="btn btn-secondary">Explore Marketplace</Link>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="features">
          <h2>Features</h2>
          <div className="feature-list">
            <div className="feature-card">
              <div className="feature-icon">🙏</div>
              <h3>Track Your Devotions</h3>
              <p>Log rosaries, Mass attendance, confessions, and more.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">🏆</div>
              <h3>Earn Rewards</h3>
              <p>Level up, earn badges, and unlock exclusive titles.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">🛒</div>
              <h3>Catholic Marketplace</h3>
              <p>Buy and sell Catholic goods within our trusted community.</p>
            </div>
          </div>
        </section>

        <section className="call-to-action">
          <h2>Start Your Journey</h2>
          <p>Join thousands of Catholics growing in faith and devotion. Sign up for free and unlock exclusive rewards.</p>
          <p className="coming-soon">Coming Soon: Honorary Certificates and Gamification Rewards!</p>
          <Link to="/signup" className="btn btn-primary">Sign Up</Link>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; 2025 littleone.life. All rights reserved.</p>
        <nav className="footer-nav">
          <Link to="/profile">Profile</Link>
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/marketplace">Marketplace</Link>
        </nav>
      </footer>
    </>
  );
}

export default Home;
