// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* .legend-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
   */
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .legend-item span:first-child {
    margin-right: 10px;
  }

/* .pie-chart-container {
    width: 100%;
    max-width: 500px; 
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} */

.pie-chart-container {
  width: 100%;
  max-width: 600px; /* Adjust the max width as needed */
  margin: auto;
  display: flex;
  flex-direction: row; /* Keep side by side */
  align-items: center;
  justify-content: space-around; /* This will add space between the chart and the legend */
}

.legend-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  padding: 10px;
}

/* Other styles remain the same */



@media screen and (max-width: 600px) {
    .pie-chart-container {
      flex-direction: column; /* Stack vertically on smaller screens */
    }
  
    .legend-container {
      padding-top: 20px; /* Add some space above the legend */
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/utils/piechart/styles.css"],"names":[],"mappings":";;AAEA;;;;;;IAMI;EACF;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;AAEF;;;;;;;;GAQG;;AAEH;EACE,WAAW;EACX,gBAAgB,EAAE,mCAAmC;EACrD,YAAY;EACZ,aAAa;EACb,mBAAmB,EAAE,sBAAsB;EAC3C,mBAAmB;EACnB,6BAA6B,EAAE,yDAAyD;AAC1F;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAE,6BAA6B;EACtD,aAAa;AACf;;AAEA,iCAAiC;;;;AAIjC;IACI;MACE,sBAAsB,EAAE,wCAAwC;IAClE;;IAEA;MACE,iBAAiB,EAAE,oCAAoC;IACzD;EACF","sourcesContent":["\n\n/* .legend-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 10px;\n  }\n   */\n  .legend-item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 5px;\n  }\n  \n  .legend-item span:first-child {\n    margin-right: 10px;\n  }\n\n/* .pie-chart-container {\n    width: 100%;\n    max-width: 500px; \n    margin: auto;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n} */\n\n.pie-chart-container {\n  width: 100%;\n  max-width: 600px; /* Adjust the max width as needed */\n  margin: auto;\n  display: flex;\n  flex-direction: row; /* Keep side by side */\n  align-items: center;\n  justify-content: space-around; /* This will add space between the chart and the legend */\n}\n\n.legend-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; /* Align items to the start */\n  padding: 10px;\n}\n\n/* Other styles remain the same */\n\n\n\n@media screen and (max-width: 600px) {\n    .pie-chart-container {\n      flex-direction: column; /* Stack vertically on smaller screens */\n    }\n  \n    .legend-container {\n      padding-top: 20px; /* Add some space above the legend */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
