// src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaChurch, FaPrayingHands, FaBook } from 'react-icons/fa';
import { signout } from '../api/auth';  // Import your signout API function
import { useUser } from '../context/UserContext'; // Custom context hook to get user info
import './Dashboard.css';

function Dashboard() {
  const navigate = useNavigate();
  const { user } = useUser();  // This should give you the authenticated user data from context
  const [devotions, setDevotions] = useState({
    rosary: 0,
    mass: 0,
    chaplet: 0,
    confession: 0,
  });
  const [today, setToday] = useState('');

  // You might fetch actual devotion counts from your backend here.
  // For now, we'll simulate with placeholder data after component mounts.
  useEffect(() => {
    // Set today's date (format as desired)
    const todayString = new Date().toLocaleDateString();
    setToday(todayString);

    // Simulate fetching devotion counts from backend.
    // Replace this with an actual API call.
    setDevotions({
      rosary: 5,
      mass: 1,
      chaplet: 3,
      confession: 0,
    });
  }, []);

  // Determine the correct avatar image based on the user's spiritual level.
  // For now, assume user object has a spiritualLevel property.
  let avatarImage;
  if (user && user.spiritualLevel !== undefined) {
    if (user.spiritualLevel <= 0) {
      avatarImage = require('../assets/images/fetus_avatar.png');
    } else if (user.spiritualLevel >= 7) {
      avatarImage = require('../assets/images/wise_avatar.png');
    } else {
      avatarImage = require('../assets/images/child_avatar.png');
    }
  } else {
    // Fallback avatar if user data is not yet loaded.
    avatarImage = require('../assets/images/child_avatar.png');
  }

  // Function to handle signout.
  const handleSignout = async () => {
    try {
      const result = await signout();
      if (result.success) {
        navigate('/signin'); // Redirect to sign in page after signout.
      } else {
        alert('Sign out failed. Please try again.');
      }
    } catch (error) {
      console.error('Sign out error:', error);
      alert('Sign out encountered an error.');
    }
  };

  // Handlers for adding a devotion.
  // In your future implementation, these handlers will start the workflow
  // to either copy the Google Form template for the devotion into the user's account,
  // or simply open a page where the user fills out the form.
  const handleAddDevotion = (devotionType) => {
    // Navigate to a route dedicated to that devotion.
    // For example, /prayers/rosary for the Rosary form.
    // Later, in that route, implement the flow to copy the form if it's the user's first time.
    navigate(`/prayers/${devotionType}`);
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar Navigation */}
      <aside className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2>littleone.life</h2>
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li><Link to="/dashboard">Devotion Tracker</Link></li>
            <li><Link to="/dashboard/achievements">Achievements</Link></li>
            <li><Link to="/marketplace">Marketplace</Link></li>
            <li><Link to="/profile">Profile</Link></li>
            <li>
              {/* Sign Out button in sidebar */}
              <button onClick={handleSignout} className="btn btn-signout">
                Sign Out
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Dashboard Area */}
      <main className="dashboard-main">
        <header className="dashboard-header">
          <div className="header-avatar">
            <img src={avatarImage} alt="User Avatar" />
          </div>
          <div className="header-info">
            <h1>Welcome, {user ? user.name : 'User'}!</h1>
            <p>Your spiritual journey for {today}</p>
            {/* Optionally, also add a sign-out button here */}
            <button onClick={handleSignout} className="btn btn-signout-header">
              Sign Out
            </button>
          </div>
        </header>

        <section className="dashboard-widgets">
          {/* Today’s Devotion Summary */}
          <div className="widget devotion-summary">
            <h2>Today's Devotions</h2>
            <div className="devotion-items">
              <div className="devotion-item">
                <FaPrayingHands size={24} />
                <span>Rosaries: {devotions.rosary}</span>
                <button onClick={() => handleAddDevotion('rosary')}>+ Add</button>
              </div>
              <div className="devotion-item">
                <FaChurch size={24} />
                <span>Mass: {devotions.mass}</span>
                <button onClick={() => handleAddDevotion('mass')}>+ Add</button>
              </div>
              <div className="devotion-item">
                <FaPrayingHands size={24} />
                <span>Chaplet: {devotions.chaplet}</span>
                <button onClick={() => handleAddDevotion('chaplet')}>+ Add</button>
              </div>
              <div className="devotion-item">
                <FaBook size={24} />
                <span>Confession: {devotions.confession}</span>
                <button onClick={() => handleAddDevotion('confession')}>+ Add</button>
              </div>
            </div>
          </div>

          {/* Progress & Gamification Panel */}
          <div className="widget progress-panel">
            <h2>Progress & Achievements</h2>
            <div className="progress-bar">
              <div className="progress-filled" style={{ width: '65%' }}>
                <span>65% to next level</span>
              </div>
            </div>
            <div className="badge-showcase">
              <span className="badge">Rosary Master</span>
              <span className="badge">Faithful Devotee</span>
            </div>
          </div>

          {/* Calendar & Reminders */}
          <div className="widget calendar-reminder">
            <h2>Calendar & Reminders</h2>
            <p>Upcoming Mass: 10:00 AM at St. Mary's Church</p>
            <p>Confession available on: Saturday 5:00 PM</p>
          </div>

          {/* Reflection Journal */}
          <div className="widget reflection-journal">
            <h2>Reflection Journal</h2>
            <textarea placeholder="Share your prayer intention or reflection..." />
            <button>Save Reflection</button>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Dashboard;
