// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/mass/components/MassTimeSelector.css */

.clock-display {
    font-family: 'Digital-7', sans-serif;
    font-size: 2em;
    color: #ff0000;
    text-align: center;
    margin-bottom: 10px;
}

.select-container {
    text-align: center;
}

.select-increased-width {
    width: 65%; /* Increased width by 30% from half */
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/mass/components/MassTimeSelector.css"],"names":[],"mappings":"AAAA,wDAAwD;;AAExD;IACI,oCAAoC;IACpC,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU,EAAE,qCAAqC;IACjD,cAAc;AAClB","sourcesContent":["/* src/components/mass/components/MassTimeSelector.css */\n\n.clock-display {\n    font-family: 'Digital-7', sans-serif;\n    font-size: 2em;\n    color: #ff0000;\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n.select-container {\n    text-align: center;\n}\n\n.select-increased-width {\n    width: 65%; /* Increased width by 30% from half */\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
