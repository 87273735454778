// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  overflow-y: auto;
  height: calc(100vh - 60px); /* Subtract the height of the navbar */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  z-index: 1000; /* Ensure it stays above other content */
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar nav ul li {
  width: 100%;
  text-align: left;
}

.sidebar nav ul li a {
  display: block;
  width: 100%;
  padding: 15px 20px;
  color: #333;
  text-decoration: none;
}

.sidebar nav ul li a.active {
  background-color: #007bff;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,0BAA0B,EAAE,sCAAsC;EAClE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa,EAAE,wCAAwC;AACzD;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["/* Sidebar.css */\n\n.sidebar {\n  width: 250px;\n  background-color: #f8f9fa;\n  overflow-y: auto;\n  height: calc(100vh - 60px); /* Subtract the height of the navbar */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 20px;\n  z-index: 1000; /* Ensure it stays above other content */\n}\n\n.sidebar nav ul {\n  list-style-type: none;\n  padding: 0;\n  width: 100%;\n}\n\n.sidebar nav ul li {\n  width: 100%;\n  text-align: left;\n}\n\n.sidebar nav ul li a {\n  display: block;\n  width: 100%;\n  padding: 15px 20px;\n  color: #333;\n  text-decoration: none;\n}\n\n.sidebar nav ul li a.active {\n  background-color: #007bff;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
