import React, { useState } from 'react';
import './Marketplace.css';

const dummyProducts = [
  {
    id: 1,
    title: 'Elegant Rosary',
    description: 'Handcrafted rosary made with natural materials.',
    image: '/images/rosary.jpg',
    price: '$25.00',
    rating: 4.5,
    category: 'Rosaries'
  },
  {
    id: 2,
    title: 'Scented Candle',
    description: 'Pure beeswax candle with a soothing aroma.',
    image: '/images/candle.jpg',
    price: '$15.00',
    rating: 4.2,
    category: 'Candles'
  },
  // Add more dummy products as needed
];

const Marketplace = () => {
  const [products] = useState(dummyProducts);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('All');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredProducts = products.filter(product =>
    (filterCategory === 'All' || product.category === filterCategory) &&
    product.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="marketplace">
      <header className="marketplace-header">
        <div className="logo">littleone.life</div>
        <nav className="nav-menu">
          <a href="/">Home</a>
          <a href="/marketplace">Marketplace</a>
          <a href="/devotions">Devotions</a>
        </nav>
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Search devotional items..." 
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </header>
      
      <div className="marketplace-content">
        <aside className="filters-sidebar">
          <h3>Categories</h3>
          <ul>
            <li onClick={() => setFilterCategory('All')}>All</li>
            <li onClick={() => setFilterCategory('Rosaries')}>Rosaries</li>
            <li onClick={() => setFilterCategory('Candles')}>Candles</li>
            <li onClick={() => setFilterCategory('Books')}>Religious Books</li>
            <li onClick={() => setFilterCategory('Statues')}>Statues</li>
          </ul>
        </aside>
        
        <section className="products-section">
          <h2>Featured Products</h2>
          <div className="product-grid">
            {filteredProducts.map(product => (
              <div key={product.id} className="product-card">
                <img src={product.image} alt={product.title}/>
                <h4>{product.title}</h4>
                <p>{product.description}</p>
                <div className="product-info">
                  <span className="price">{product.price}</span>
                  <span className="rating">{product.rating}★</span>
                </div>
              </div>
            ))}
          </div>
        </section>
        
        <aside className="seller-info">
          <h3>Seller Information</h3>
          <div className="seller-profile">
            <img src="/images/seller-profile.png" alt="Seller"/>
            <div>
              <h4>littleone</h4>
              <p>Passionate about sharing devotional products and Catholic art.</p>
              <p>Rating: 4.8★</p>
            </div>
          </div>
        </aside>
      </div>
      
      <footer className="marketplace-footer">
        <p>&copy; {new Date().getFullYear()} littleone.life. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Marketplace;
