// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ConfessionForm.css */

.form-container {
    margin: 20px 0;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    display: inline-block;
    background-color: #dc3545;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  
  .radio-button-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-button-container label {
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/confession/components/ConfessionForm.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["/* ConfessionForm.css */\n\n.form-container {\n    margin: 20px 0;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-control {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .submit-button {\n    display: inline-block;\n    background-color: #007bff;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 10px;\n  }\n  \n  .submit-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .cancel-button {\n    display: inline-block;\n    background-color: #dc3545;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 10px;\n    margin-left: 10px;\n  }\n  \n  .cancel-button:hover {\n    background-color: #c82333;\n  }\n  \n  .radio-button-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .radio-button-container label {\n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
