import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from '../api/authHook';

const PrivateRoute = ({ component: Component }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return null; // Optionally replace with a spinner
  }

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
