// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChurchSearch.css */

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 15px;
}

.search-container .filter-input-container {
    flex-grow: 1;
    position: relative; /* Ensure the clear icon is positioned relative to this container */
}

.search-container input {
    width: 100%;
    padding-right: 30px;
}

.clear-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: red;
}

.search-icon-container {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.search-icon {
    cursor: pointer;
    color: #000; /* Ensure the color is set */
}

.search-icon.red {
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/mass/components/ChurchSearch.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB,EAAE,mEAAmE;AAC3F;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW,EAAE,4BAA4B;AAC7C;;AAEA;IACI,UAAU;AACd","sourcesContent":["/* ChurchSearch.css */\n\n.search-container {\n    position: relative;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto 15px;\n}\n\n.search-container .filter-input-container {\n    flex-grow: 1;\n    position: relative; /* Ensure the clear icon is positioned relative to this container */\n}\n\n.search-container input {\n    width: 100%;\n    padding-right: 30px;\n}\n\n.clear-icon {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: red;\n}\n\n.search-icon-container {\n    margin-left: 10px;\n    display: flex;\n    align-items: center;\n}\n\n.search-icon {\n    cursor: pointer;\n    color: #000; /* Ensure the color is set */\n}\n\n.search-icon.red {\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
