// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChurchSearch.css */

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .filter-input-container {
    position: relative;
    margin-right: 10px;
  }
  
  .filter-input-container input {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .clear-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
  }
  
  .clear-icon:hover {
    color: #555;
  }
  
  .search-icon-container {
    cursor: pointer;
  }
  
  .search-icon {
    font-size: 20px;
  }
  
  .search-icon.red {
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/confession/components/ChurchSearch.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ","sourcesContent":["/* ChurchSearch.css */\n\n.search-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  \n  .filter-input-container {\n    position: relative;\n    margin-right: 10px;\n  }\n  \n  .filter-input-container input {\n    width: 200px;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .clear-icon {\n    position: absolute;\n    right: 5px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #888;\n  }\n  \n  .clear-icon:hover {\n    color: #555;\n  }\n  \n  .search-icon-container {\n    cursor: pointer;\n  }\n  \n  .search-icon {\n    font-size: 20px;\n  }\n  \n  .search-icon.red {\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
