// src/pages/Terms.js

import React from 'react';

function Terms() {
  return (
    <div className="content-container">
      <h1>Terms of Service</h1>
      <p>Effective Date: 2/14/2025</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing littleone.life, you agree to comply with these terms.</p>

      <h2>2. User Responsibilities</h2>
      <p>You agree not to misuse the platform or violate applicable laws.</p>

      <h2>3. Account & Data</h2>
      <p>You are responsible for securing your Google account and ensuring your data privacy.</p>

      <h2>4. Changes to Terms</h2>
      <p>We may update these Terms at any time. Continued use means you accept the updates.</p>

      <h2>5. Contact</h2>
      <p>For questions, contact us at <strong>thebillvideos@gmail.com</strong>.</p>
    </div>
  );
}

export default Terms;
