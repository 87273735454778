// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prayer-space {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
}

.space-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.user-list {
    display: flex;
    flex-wrap: wrap;
}

.user {
    width: 100px;
    text-align: center;
    margin: 10px;
}

.user img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.user-info {
    margin-top: 5px;
}

.leave-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/prayerspace/PrayerSpace.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,6BAA6B;IAC7B,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".prayer-space {\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    width: 100%;\n    max-width: 600px;\n    margin: 0 auto;\n    background-color: white;\n}\n\n.space-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #ddd;\n    padding-bottom: 10px;\n    margin-bottom: 20px;\n}\n\n.user-list {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.user {\n    width: 100px;\n    text-align: center;\n    margin: 10px;\n}\n\n.user img {\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n}\n\n.user-info {\n    margin-top: 5px;\n}\n\n.leave-button {\n    background-color: #ff4d4f;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    border-radius: 5px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
