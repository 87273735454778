// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/mass/components/ChurchFormToggle.css */

.church-form-toggle {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.church-form-toggle .btn {
    margin-top: 1rem;
}

.church-form-toggle .w-100 {
    margin-top: 1rem;
}

.church-form-toggle .d-flex {
    justify-content: center;
}

.church-form-toggle .mt-3 {
    margin-top: 1rem;
}

.church-form-toggle .list-group-item {
    text-align: left;
}

.church-form-toggle .form-group {
    margin-top: 1rem;
}

.church-form-toggle .btn-primary, .church-form-toggle .btn-secondary {
    margin-right: 0.5rem;
}

.church-form-toggle .btn-success {
    margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/mass/components/ChurchFormToggle.css"],"names":[],"mappings":"AAAA,wDAAwD;;AAExD;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["/* src/components/mass/components/ChurchFormToggle.css */\n\n.church-form-toggle {\n    width: 100%;\n    text-align: center;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.church-form-toggle .btn {\n    margin-top: 1rem;\n}\n\n.church-form-toggle .w-100 {\n    margin-top: 1rem;\n}\n\n.church-form-toggle .d-flex {\n    justify-content: center;\n}\n\n.church-form-toggle .mt-3 {\n    margin-top: 1rem;\n}\n\n.church-form-toggle .list-group-item {\n    text-align: left;\n}\n\n.church-form-toggle .form-group {\n    margin-top: 1rem;\n}\n\n.church-form-toggle .btn-primary, .church-form-toggle .btn-secondary {\n    margin-right: 0.5rem;\n}\n\n.church-form-toggle .btn-success {\n    margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
