import React, { useState, useEffect } from 'react';
import { getUserRosaryForm, copyRosaryForm } from '../../api/googleForms';

const RosaryTracker = () => {
    const [formUrl, setFormUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [copying, setCopying] = useState(false);

    useEffect(() => {
        const fetchRosaryForm = async () => {
            setLoading(true);
            try {
                const response = await getUserRosaryForm();
                if (response.formExists) {
                    setFormUrl(response.formUrl);
                }
            } catch (error) {
                console.error("Failed to fetch Rosary form.");
            }
            setLoading(false);
        };

        fetchRosaryForm();
    }, []);

    const handleCopyForm = async () => {
        setCopying(true);
        try {
            const { formUrl } = await copyRosaryForm();
            setFormUrl(formUrl);
        } catch (error) {
            alert('Failed to copy Rosary Form. Please try again.');
        }
        setCopying(false);
    };

    return (
        <div>
            <h2>Rosary Tracker</h2>
            {loading ? (
                <p>Loading...</p>
            ) : formUrl ? (
                <div>
                    <p>Your Rosary Form is ready!</p>
                    <iframe
                        src={formUrl}
                        width="640"
                        height="2039"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        title="Rosary Form"
                    >
                        Loading…
                    </iframe>
                </div>
            ) : (
                <div>
                    <p>You have not set up your Rosary Tracker yet.</p>
                    <button onClick={handleCopyForm} disabled={copying}>
                        {copying ? 'Copying...' : 'Copy Rosary Form'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default RosaryTracker;
