// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.02);
    box-shadow: 0 0 15px 5px gold;
  }
  
  .animate-progress {
    transition: width 0.6s ease-in-out;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/dashboards/crm/crmdashboard2/cards/MassCard.css"],"names":[],"mappings":"AAAA;IACI,qDAAqD;EACvD;;EAEA;IACE,sBAAsB;IACtB,6BAA6B;EAC/B;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":[".card {\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n  }\n  \n  .card:hover {\n    transform: scale(1.02);\n    box-shadow: 0 0 15px 5px gold;\n  }\n  \n  .animate-progress {\n    transition: width 0.6s ease-in-out;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
