// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/devotions/Rosaries.css */
.rosaries {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
  }
  
  .rosary-icon {
    color: #4caf50;
    margin-right: 10px;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
  }
  
  .progress {
    height: 20px;
    background-color: #4caf50;
  }
  
  .google-signin-btn, .add-rosary-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4285F4;
    color: white;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/devotions/Rosaries.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB","sourcesContent":["/* src/pages/devotions/Rosaries.css */\n.rosaries {\n    padding: 20px;\n    max-width: 800px;\n    margin: auto;\n    text-align: center;\n  }\n  \n  .rosary-icon {\n    color: #4caf50;\n    margin-right: 10px;\n  }\n  \n  .progress-bar {\n    width: 100%;\n    background-color: #e0e0e0;\n    border-radius: 5px;\n    overflow: hidden;\n    margin: 10px 0;\n  }\n  \n  .progress {\n    height: 20px;\n    background-color: #4caf50;\n  }\n  \n  .google-signin-btn, .add-rosary-btn {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: #4285F4;\n    color: white;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
