// src/api/auth.js
const API = process.env.REACT_APP_API || 'https://www.littleone.life/api';

/**
 * Check if the user is authenticated via the server session.
 * This function calls the /auth/check-session endpoint, which returns
 * a JSON object with an "isAuthenticated" property and user data if available.
 */
export const checkSession = async () => {
  try {
    const response = await fetch(`${API}/auth/check-session`, {
      method: 'GET',
      credentials: 'include', // Ensure session cookie is included
    });

    if (!response.ok) {
      throw new Error('Failed to check session');
    }

    const sessionData = await response.json();
    return sessionData; // { isAuthenticated: true, user: { ... } } or { isAuthenticated: false }
  } catch (error) {
    console.error('Error checking session:', error);
    return { isAuthenticated: false };
  }
};

/**
 * Sign out the user by calling the /auth/signout endpoint.
 * This destroys the server session and clears the session cookie.
 */
export const signout = async () => {
  try {
    const response = await fetch(`${API}/auth/signout`, {
      method: 'POST',
      credentials: 'include', // Ensure session cookie is sent
    });

    if (response.ok) {
      return { success: true };
    } else {
      throw new Error('Signout failed');
    }
  } catch (error) {
    console.error('Signout error:', error);
    return { success: false };
  }
};

/* 
// The following functions are no longer needed because all authentication 
// is handled via Google OAuth with redirects. In your new workflow, you simply
// redirect the user to the Google OAuth endpoint (e.g., by setting window.location.href)
// on sign-in or sign-up actions.
// 
// export const googleSignIn = () => {
//   window.location.href = `${API}/auth/google`;
// };
//
// Similarly, email/password signup/signin, checkUsernameAvailability, 
// forgotPassword, resetPassword, etc., are removed.
*/
