// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/speechrecognition/SpeechRecognitionComponent.css */

.speech-recognition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .microphone-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .microphone-icon {
    font-size: 40px; /* Adjust the size of the icon */
    color: #ff6347; /* Change the color of the microphone icon (Tomato color) */
    transition: color 0.3s ease;
  }
  
  .microphone-icon:hover {
    color: #d9534f; /* Darker shade of red on hover */
  }
  
  sup {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/speechrecognition/SpeechRecognitionComponent.css"],"names":[],"mappings":"AAAA,oEAAoE;;AAEpE;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,aAAa;EACf;;EAEA;IACE,eAAe,EAAE,gCAAgC;IACjD,cAAc,EAAE,2DAA2D;IAC3E,2BAA2B;EAC7B;;EAEA;IACE,cAAc,EAAE,iCAAiC;EACnD;;EAEA;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB","sourcesContent":["/* src/components/speechrecognition/SpeechRecognitionComponent.css */\n\n.speech-recognition-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  .microphone-button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    outline: none;\n  }\n  \n  .microphone-icon {\n    font-size: 40px; /* Adjust the size of the icon */\n    color: #ff6347; /* Change the color of the microphone icon (Tomato color) */\n    transition: color 0.3s ease;\n  }\n  \n  .microphone-icon:hover {\n    color: #d9534f; /* Darker shade of red on hover */\n  }\n  \n  sup {\n    font-size: 14px;\n    color: #333;\n    margin-bottom: 8px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
