// src/context/UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
// jwtDecode may no longer be necessary if the session endpoint returns user data directly
// import { jwtDecode } from "jwt-decode";  
// import { getPrayerSettings } from '../api/user';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userPreferences, setUserPreferences] = useState({
        prayerSettings: [],
        gender: '',
        novenas: [],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // In a session-based system, you might not have a JWT in a cookie,
        // so instead rely on the checkSession endpoint (handled in useAuth) to populate user data.
        // This effect could be used to fetch additional user preferences once the user is known.
        if (user) {
            // const fetchUserPreferences = async () => {
            //     try {
            //         const settings = await getPrayerSettings(user._id);
            //         setUserPreferences(prev => ({ ...prev, prayerSettings: settings }));
            //     } catch (error) {
            //         console.error('Error fetching prayer settings:', error);
            //     } finally {
            //         setLoading(false);
            //     }
            // };
            // fetchUserPreferences();
        } else {
            setLoading(false);
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser, userPreferences, setUserPreferences, loading }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
